@import '../../../src/sass/variable';
@import '../../../src/sass/functions';

.layoutContent {
    height: 100%;
    position: relative;
    padding: 24px 24px 0 0;
    // min-height: rem(100);

    aside {
        height: 100%;
        float: left;
        width: 252px;
        min-width: 252px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 24px;

        .upperHead {
            display: flex;
            padding: 0 24px;
            align-items: center;
            margin-bottom: 28px;
        }

        a {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-decoration: none;
            color: inherit;
            display: flex;
            padding: 8px 24px 8px 24px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            position: relative;
            margin-bottom: 8px;

            &.active {
                color: $green800;
                border-radius: 0 50px 50px 0;
                background: $green700;
                font-weight: 500;

                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: $green1100;
                }

                svg {
                    path {
                        stroke: $green600;
                    }
                }
            }
        }

        .currentModel {
            margin-bottom: 8px;
            padding: 8px 24px 8px 36px;
            color: $green800;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            svg {
                margin-right: 8px;
            }
        }
        .userCard {
            height: 72px;
            margin-top: 16px;
            margin-left: 24px;
            border: 1px solid $green1400;
            border-radius: 8px;
            display: flex;
            gap: 16px;
            padding: 16px;
            justify-content: space-between;
            align-items: center;
            .details {
                display: flex;
                align-items: center;
                gap: 16px;
                .name {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #000000;
                }
                .role {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #344054;
                }
            }
            .icon {
                transform: rotate(180deg);
                cursor: pointer;
                transition: transform 0.2s linear;
                &.open {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .mainContent {
        float: left;
        width: calc(100% - 252px);
        padding-left: 24px;
        padding-bottom: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow: hidden;
        & header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            span {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                text-align: left;
                color: #1d2939;
            }
        }
        .children {
            flex-grow: 1;
            overflow: auto;
        }
    }
}

.menuWrapper {
    background: $white;
    border-radius: 8px;
    padding: 4px 0;
    min-width: 200px;
    .menuItems {
        height: 40px;
        width: 100%;
        padding: 14px 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1d2939;
        text-decoration: none;
        cursor: pointer;
        svg {
            path {
                stroke: $black600;
            }
        }
        &.active {
            color: #026b62 !important;
            background-color: $green500 !important;
            svg {
                path {
                    stroke: $green600 !important;
                }
            }
        }
        &.logout {
            color: $red100 !important;
            svg {
                path {
                    stroke: $red100 !important;
                }
            }
        }
        &:hover {
            background-color: $grey100;
            svg {
                path {
                    stroke: $black200;
                }
            }
        }
    }
}

.creditsMenu {
    padding: 8px;
    background-color: $green500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .creditDetails {
        display: flex;
        gap: 8px;
        .credits {
            color: $siteTextColor;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            .creditValue {
                //styleName: Text sm/Semibold;
                font-weight: 600;
                color: #1d2939;
            }
        }
        .creditIcon {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: $green1200;
            svg {
                path {
                    stroke: $green1400;
                }
            }
        }
    }
    .cIcon {
        transform: rotate(90deg);
        path {
            stroke: $black100;
        }
    }
    &.exhausted {
        background-color: #fef3f2;
        .creditDetails {
            .credits {
                .creditValue {
                    color: #d92d20;
                }
            }
            .creditIcon {
                background-color: #fecdca;
                svg {
                    path {
                        stroke: #b42318;
                    }
                }
            }
        }
    }
}

.helloBarCreditExhausted {
    height: 44px;
    background-color: #fef3f2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: $siteTextColor;
    span {
        color: #b42318;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            height: 16px;
            width: 16px;
            path {
                stroke: #b42318;
            }
        }
    }
}

.requestCreditDialog {
    .header {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: $black200;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        padding: 16px 24px;
        button {
            &.secondary {
                color: $black300 !important;
                border: 1px solid $grey800 !important;
                background: $white;
            }
        }
    }
    .content {
        padding: 0 24px;
        .creditInfo {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #667085;
            margin-bottom: 32px;
        }
        .inputWrap {
            margin-top: 20px;

            label {
                color: $black300;
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 12px;
            }

            .MuiInputBase-root {
                border-radius: 8px;
                border: 1px solid $grey300;
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

                input {
                    height: inherit;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 10px 14px;
                }

                fieldset {
                    display: none;
                }
            }
        }
        .chipGroup {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            justify-content: space-between;
            padding-top: 12px;
            .chipItem {
                flex-grow: 1;
                border: 2px solid transparent;
                background: $green1000;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                color: $green800;
                height: 36px;
                cursor: pointer;
                &.selected {
                    border-color: $green1400;
                }
            }
        }
    }
}
