@import '../../sass/variable';

.uploadStatusPopoverContainer {
    position: fixed;
    bottom: 48px;
    right: 48px;
    border-radius: 8px;
    width: 312px;

    box-shadow: 0px 20px 24px -4px #10182814;

    .uploadStatusHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        background: $grey600;
        border-radius: 8px;
        div {
            display: flex;
            gap: 8px;
            align-items: center;
            svg {
                cursor: pointer;
                path {
                    stroke: $black300;
                }
            }
            .chevron {
                transform: rotate(0deg);
                transition: transform 0.2s linear;
                &.open {
                    transform: rotate(180deg);
                }
            }
        }
        &.open {
            border-radius: 8px 8px 0 0;
        }
    }
    .uploadStatusBody {
        overflow: auto;
        height: 0px;
        transition: height 0.2s linear;
        background-color: $white;
        &.open {
            height: 168px;
            border-radius: 0 0 8px 8px;
        }
        .bodyItem {
            height: 42px;
            padding: 8px 16px;
            display: flex;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #1d2939;
            width: 100%;
            overflow: hidden;
            .name {
                flex-grow: 1;
                overflow: hidden;
            }
            .statusWrap {
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 18px;
                color: $green600;
                border: 1px solid $green1100;
                background: $green1000;
                display: inline-block;
                font-weight: 500;

                span {
                    margin-left: 8px;
                }

                &.Uploaded {
                    color: $green400;
                    border-color: $green200;
                    background: $green300;
                }

                &.Processing,
                &.Flagged {
                    color: $red200;
                    border-color: $orange100;
                    background: $yellow200;
                }

                &.Rejected,
                &.Failed {
                    color: $red200;
                    border-color: $red200;
                    background: $yellow200;
                }
            }
        }
    }
}
