// Colors
$siteTextColor: #1d2939;

$white: #ffffff;

$black: #000000;
$black100: #667085;
$black200: #101828;
$black300: #344054;
$black400: #072c28;
$black500: #222222;
$black600: #475467;

$grey100: #f2f2f2;
$grey200: #f8f5ff;
$grey300: #d0d5dd;
$grey400: #eaecf0;
$grey500: #f9fafb;
$grey600: #f2f4f7;
$grey700: #98a2b3;
$grey800: #d0d5dd;

$red100: #d92d20;
$red200: #b54708;
$red300: #f04438;

$yellow100: #d88f00;
$yellow200: #fef0c7;

$orange100: #f79009;

$blue100: #0679c3;

$green100: #28ebc4;
$green200: #12b76a;
$green300: #d1fadf;
$green400: #027a48;
$green500: #eefbfa;
$green600: #038e83;
$green700: #ddefee;
$green800: #026b62;
$green900: #f8ffff;
$green1000: #e6f7f6;
$green1100: #36c1b6;
$green1200: #cdf0ed;
$green1300: #b4e8e4;
$green1400: #04b2a4;
$green1500: #e5f7f6;
