@import '../../sass/variable';

.activityLogWrapper {
    flex-grow: 1;
    background-color: #ffffff;
    overflow: auto;
    border-radius: 10px;
}

.docNavigation {
    display: flex;
    height: 44px;
    width: 100%;
    color: $black500;
    background: $grey400;
    margin-bottom: 24px;

    li {
        padding: 10px 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        &.highLightRow {
            border-radius: 12px 12px 0 0;
            background: $white;
            pointer-events: none;
        }

        .docInTabs {
            padding: 2px 8px;
            border-radius: 12px;
            gap: 10px;
            height: 24px;
            background: $green1400;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: $white;
        }
    }
}

.heightss {
    height: calc(100vh - 112px) !important;
}
