@import '../../sass/variable';
@import '../../sass/functions';

.rightButtons {
    float: right;

    button {
        margin-left: 16px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.settingsBtn {
    margin-left: 16px;
}

.documentsWrap {
    position: relative;
    background-color: $white;
    .docNavigation {
        display: flex;
        margin-bottom: 24px;

        li {
            float: left;
            width: 17%;
            padding: 12px 18px;
            color: $black500;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            background: $grey400;
            cursor: pointer;

            &.highLightRow {
                border-radius: 12px 12px 0 0;
                background: $white;
                pointer-events: none;
            }

            .docInTabs {
                padding: 2px 8px;
                height: 24px;
                border-radius: 12px;
                gap: 10px;
                background: $green1400;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                color: $white;
            }
        }
    }

    .docsTable {
        background: $white;
        color: $siteTextColor;
        box-shadow: none;
        height: calc(100% - 68px);
        overflow-y: auto;

        .emptyContent {
            margin: 90px 0 120px;
            text-align: center;

            h2 {
                margin: rem(2) 0;
                color: $black200;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }

            p {
                color: $black600;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .MuiTable-root {
            thead {
                position: sticky;
                top: 0;
                z-index: 1;
                tr {
                    th {
                        font-family: inherit;
                        position: relative;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px;
                        color: $black100;
                        border: 0;
                        background: $grey500;
                        padding: 10px 16px;
                        border-bottom: 1px solid $grey400;
                    }
                }
            }
            tbody {
                tr {
                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }

                    &.highLightRow {
                        td {
                            background: $green500;

                            &:last-child {
                                background: $green1200;
                            }
                        }
                    }

                    td {
                        font-family: inherit;
                        border-color: $grey400;
                        position: relative;
                        padding: 18px 16px;
                        color: $black100;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;

                        &:nth-child(2) {
                            font-weight: 500;
                            color: $black200;
                            cursor: pointer;
                        }

                        .statusWrap {
                            padding: 4px 8px;
                            border-radius: 4px;
                            font-size: 12px;
                            line-height: 18px;
                            color: $green600;
                            border: 1px solid $green1100;
                            background: $green1000;
                            display: inline-block;
                            font-weight: 500;

                            span {
                                margin-left: 8px;
                            }

                            &.Uploaded {
                                color: $green400;
                                border-color: $green200;
                                background: $green300;
                            }

                            &.Processing,
                            &.Flagged {
                                color: $red200;
                                border-color: $orange100;
                                background: $yellow200;
                            }

                            &.Rejected,
                            &.Failed {
                                color: $red200;
                                border-color: $red200;
                                background: $yellow200;
                            }
                        }

                        &:last-child {
                            padding: 0;
                            cursor: pointer;

                            .verticalIcon {
                                height: 100%;
                                width: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                color: $grey700;

                                svg {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
