@import '../../sass/variable';
@import '../../sass/functions';

.settingsWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: calc(100vh - 48px);
    overflow: hidden;

    .upperHead {
        h1 {
            font-weight: 400;
            display: flex;
            align-items: center;
            font-size: 20px;
            line-height: 30px;
            a {
                color: #111;
                text-decoration: none;
            }
            .headerArrow {
                transform: rotate(180deg);
            }
            span {
                font-weight: 600;
            }
        }
    }
    .settingsWrapper {
        flex-grow: 1;
        background-color: #ffffff;
        overflow: auto;
        border: 1px solid $grey400;
        border-radius: 10px;
        display: flex;
        gap: 10px;
        aside {
            border-right: 1px solid $grey800;
            padding: 24px 0;
            .active {
                background-color: $green500;
                color: $green600;
            }
        }
        .settingsContent {
            padding: 24px;
            height: 100%;
            overflow: hidden;
            width: calc(100% - 250px);
            .settingsContentWrapper {
                display: flex;
                flex-direction: column;
                gap: 32px;
                height: 100%;
                .subHead {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h2 {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        text-align: left;
                        color: #1d2939;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .countMembers {
                            background: #eefbfa;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 18px;
                            text-align: center;
                            color: #038e83;
                            padding: 2px 8px;
                            border-radius: 11px;
                        }
                    }
                }
                .mainContainer {
                    flex-grow: 1;
                    overflow: auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &.modelSettingContainer {
                        max-width: 512px;
                    }
                    &.fieldSettingContainer {
                        max-width: 95%;
                    }

                    .MuiDataGrid-filler {
                        & > div {
                            border-top: 0 !important;
                        }
                    }
                    .MuiDataGrid-columnHeaders {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        & > div {
                            background-color: #f9fafb;
                            .MuiDataGrid-columnHeader {
                                border-color: #eaecf0;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                                text-align: left;
                                color: #667085;
                            }
                        }
                    }
                    .MuiDataGrid-row {
                        min-height: 72px !important;
                        max-height: 72px !important;
                        .MuiDataGrid-cell {
                            height: 72px;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .MuiCheckbox-root {
                        .MuiSvgIcon-root {
                            path {
                                fill: #d0d5dd;
                            }
                        }
                    }
                    .inputWrap {
                        margin-bottom: 0;
                    }
                    .assignedUserHeader {
                        margin: 0;
                        margin-top: 4px;
                    }
                    .modelSettingsRadio {
                        display: flex;
                        justify-content: space-between;
                        label {
                            margin-bottom: 0;
                        }
                        span div {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            text-align: left;
                            color: #475467;
                        }
                    }
                }
            }
        }
    }
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .field {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: center;
        &.title {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: $siteTextColor;
            span {
                flex-grow: 1;
                width: 50%;
                &:first-child {
                    padding-left: 32px;
                }
            }
        }
        & > div {
            flex-grow: 1;
            display: flex;
            align-items: center;
            gap: 8px;
            width: calc(50% - 4px);
            .MuiTextField-root,
            .selectWrap {
                flex-grow: 1;
            }
            &.key {
                & > svg {
                    font-size: 24px;
                    color: $black100;
                    cursor: pointer;
                }
                .MuiTextField-root {
                    color: #344054;
                }
                .MuiInputBase-root {
                    border-radius: 8px;
                }
                .MuiInputBase-root:focus {
                    outline: none;
                    border: none;
                }
                .MuiOutlinedInput-notchedOutline {
                    border-color: $grey300;
                }
            }
            &.value {
                svg {
                    cursor: pointer;
                    path {
                        stroke: $black100;
                    }
                }
            }
        }
    }
}

.fieldSettingsDrawer {
    .MuiBackdrop-root {
        background-color: transparent;
    }

    .MuiPaper-root {
        box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
        background: $white;
    }

    .filterContent {
        padding: 24px;
        color: $black;
        height: 100%;
        font-family: 'Inter';

        h2 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin: 0 0 32px;
            position: relative;

            .closeButton {
                position: absolute;
                padding: 0;
                right: 0;
                width: 24px;
                height: 24px;
                color: $black200;
            }
        }

        .rowDrawer {
            margin-bottom: 20px;

            h3 {
                color: $siteTextColor;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin: 0 0 8px 0;
            }

            .textContent {
                border-radius: 8px;
                border: 1px solid $grey300;
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                color: $black300;
                font-size: 16px;
                font-weight: 400;
                line-height: 40px;
                padding: 0 14px;
            }
        }
    }
    .MuiTextField-root {
        width: 100%;
        color: #344054;
    }
    .MuiInputBase-root {
        border-radius: 8px;
    }
    .MuiInputBase-root:focus {
        outline: none;
        border: none;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: $grey300;
    }
    button {
        margin-top: 24px;
        width: 100%;
        margin: 0;
        margin-top: 24px;
    }
}
