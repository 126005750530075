@import '../../sass/variable';

.addModalRoot {
    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 24px;
        width: 520px;
        border-radius: 12px;
        background: $white;
        box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
        color: $black200;
        outline: none;

        h2 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin: 0 0 16px;
            position: relative;

            .closeButton {
                position: absolute;
                padding: 0;
                right: 0;
                width: 24px;
                height: 24px;
                color: $black200;
            }
        }

        p {
            color: $black100;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
        }

        .dataSection {
            margin-top: 32px;

            button {
                color: $green800 !important;
                border-color: $green1000;
                background: $green1000;
                margin: 0 12px 12px 0;

                &.activeButton {
                    border: 2px solid $green1100;
                    pointer-events: none;
                }
            }
        }

        .selectWrap {
            margin-top: 20px;
            font-size: 14px;
        }

        .inputWrap {
            margin-top: 20px;

            label {
                color: $black300;
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 12px;
            }

            .MuiInputBase-root {
                border-radius: 8px;
                border: 1px solid $grey300;
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

                input {
                    height: inherit;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 10px 14px;
                }

                fieldset {
                    display: none;
                }
            }
        }

        .errorInfo {
            margin-top: 16px;
            li {
                font-size: 14px;
                font-weight: 600;
                color: $red100;
            }
        }

        .modalDescription {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $siteTextColor;
        }

        .buttonWrap {
            text-align: center;
            margin-top: 32px;

            button {
                position: relative;
                padding: 10px 18px;
                width: calc(50% - 6px);
                margin-left: 12px;
                font-size: 16px;

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                &:first-child {
                    color: $black300 !important;
                    border-color: $grey300;
                    background: $white;
                    margin: 0;
                }
            }
        }
    }
}
