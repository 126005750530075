@import 'functions';
@import 'variable';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    height: 100%;
    color: $siteTextColor;

    body {
        box-sizing: border-box;
        height: 100%;
        font-size: 16px;
        font-family: 'Inter';
        margin: 0;
        color: #000000;
        font-weight: 400;
        line-height: 1.43;

        &.noScroll {
            overflow: hidden;
        }

        #root {
            min-height: rem(100);
        }

        .mainContentWrapper {
            // height:100%;
            height: 100vh;
            overflow: auto;
            background: $green500;
            min-height: rem(100);

            &.accessToken {
                background: $grey600;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        p {
            margin: 0;
        }

        * {
            box-sizing: border-box;
        }

        .ellipsisPro {
            overflow: hidden;
            word-wrap: break-word;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }

        .clearfix {
            &:after {
                visibility: hidden;
                display: block;
                font-size: 0;
                content: ' ';
                clear: both;
                height: 0;
            }
        }

        .MuiButton-root {
            border-radius: 8px;
            padding: 10px 16px;
            background: $black400;
            // border: 1px solid $black400;
            border: 0px;
            box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
            color: $white !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-transform: none;
            font-family: inherit;
            cursor: pointer;
            outline: none;
            box-sizing: border-box;

            &.Mui-disabled {
                opacity: 0.5;
            }

            &.secondaryBtn {
                border-color: $green1300;
                background: $green500;
                color: $green600 !important;

                &:hover {
                    background: $green500;
                }
            }

            &:hover {
                background: $black400;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }

        .MuiButton-root.MuiButton-outlined {
            border-radius: 8px;
            padding: 10px 16px;
            background: $white;
            border: 1px solid $black400;
            box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
            color: $black400 !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-transform: none;
            font-family: inherit;
            cursor: pointer;
            outline: none;

            &.Mui-disabled {
                opacity: 0.5;
            }

            &:hover {
                background: $grey200;
            }

            svg {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }
        .selectWrap,
        .textAreaWrap {
            position: relative;

            &.addError {
                .MuiInputBase-root {
                    fieldset {
                        border-color: $red100 !important;
                    }

                    &.Mui-focused,
                    &:hover {
                        fieldset {
                            border-color: $red100 !important;
                        }
                    }
                }
            }

            & > label {
                font-family: inherit;
                font-size: inherit;
                color: $black300;
                display: block;
                margin-bottom: rem(1.2);
                text-overflow: inherit;
                white-space: normal;
                overflow: auto;
                font-weight: 500;
            }

            &.emptySelect {
                .MuiInputBase-root {
                    .MuiSelect-select {
                        color: $grey300;
                        -webkit-text-fill-color: $grey300;
                    }
                }
            }

            textarea,
            input {
                color: $black300;
            }

            .MuiInputBase-root {
                font-size: inherit;
                font-family: inherit;

                .MuiSelect-select {
                    min-height: inherit;
                    padding: 0 14px;
                    color: $black300;
                    font-weight: 300;
                    line-height: 40px;
                    font-size: inherit;
                    font-family: inherit;
                }

                svg {
                    font-size: 20px;
                    right: rem(1.5);
                    top: calc(50% - 10px);
                }

                fieldset {
                    border: 1px solid $grey300;
                    border-radius: 8px;
                }

                &.Mui-focused,
                &:hover {
                    fieldset {
                        border-color: $grey300;
                    }
                }
            }
        }
    }
}

.userOptionsPaper {
    max-height: rem(25) !important;
    margin-top: 4px;
    border-radius: 2px !important;
    box-shadow: 0 2px 4px -1px rgba(5, 28, 44, 0.2), 0 0 1px rgba(5, 28, 44, 0.15) !important;

    & > ul {
        padding: 0;
        max-height: unset;

        .userOptions {
            font-family: 'Inter';
            background: $white;
            color: $siteTextColor;
            position: relative;
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 400;

            &:hover {
                background: $grey100;
            }

            &.Mui-selected,
            &.MuiAutocomplete-option[aria-selected='true'] {
                background: $grey100;
            }

            .key {
                width: 40%;
                color: $siteTextColor;
                position: relative;
                padding-left: 12px;
                display: block;
                overflow: hidden;
                word-wrap: break-word;
                text-overflow: ellipsis;
                white-space: nowrap;

                .circle {
                    left: -4px;
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    top: 8px;
                }

                .rectangle {
                    display: inline-block;
                    padding: 4px 8px;
                    margin-left: 8px;
                    border-radius: 4px;
                    border: 1px solid $green1300;
                    background: $green1000;
                    color: $green600;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 14px;
                }
            }

            .value {
                width: 60%;
                padding-left: 16px;
                color: $black100;
                position: relative;
                display: block;
            }
        }
    }
}

// Universal custom scrollbar styles
// WebKit-based browsers
::-webkit-scrollbar {
    width: 8px; // Adjusted to a more standard size
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $green1300;
    border-radius: 4px;
    &:hover {
        background: #9be0db;
    }
}

// // Firefox
// * {
//     scrollbar-width: 2px;
//     scrollbar-color: $black100 $grey100;
// }

.phone-number-input {
    .MuiInputBase-root {
        padding-left: 0;
        .MuiInputBase-root {
            border: 0 !important;
            box-shadow: unset !important;
        }
    }
    #country-code-select {
        padding-top: 0;
        padding-bottom: 0;
    }
    input {
        padding-left: 0 !important;
    }
}
