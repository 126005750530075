@import '../../sass/variable';
@import '../../sass/functions';

.reviewDocument {
    position: relative;
    height: 100%;
    min-height: rem(100);
    max-height: rem(100);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    &.selectedTable {
        .bottomDummy {
            display: block;
        }
    }

    .backButton {
        padding: 6px 0 4px;
        border-radius: 8px;
        border: 1px solid $grey300;
        background: $white;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        cursor: pointer;
        width: 36px;
        text-align: center;
        position: fixed;
        left: 32px;
        top: 26px;
        z-index: 2;

        svg {
            font-size: 20px;
        }
    }

    .leftWrapperWithHeader {
        flex-grow: 1;
        max-width: calc(100% - 450px);
    }

    header {
        padding: 20px 0 0 90px;
        width: 100%;

        .selectWrap {
            position: relative;
            display: inline-block;
            background: $white;
            top: -5px;
            font-family: inherit;

            & * {
                font-family: inherit;
            }

            & > label {
                color: $black300;
                display: block;
                margin-bottom: rem(1.2);
                text-overflow: inherit;
                white-space: normal;
                overflow: auto;
                font-weight: 300;
            }

            &.emptySelect {
                .MuiInputBase-root {
                    .MuiSelect-select {
                        color: $siteTextColor;
                        -webkit-text-fill-color: $siteTextColor;
                    }
                }
            }

            textarea,
            input {
                color: $black300;
            }

            .MuiInputBase-root {
                font-size: inherit;
                font-family: inherit;

                .MuiSelect-select {
                    min-height: inherit;
                    padding: 6px 44px 8px 16px;
                    color: $siteTextColor;
                    font-size: 14px;
                    font-weight: 400;
                }

                svg {
                    font-size: 24px;
                    right: 12px;
                    top: calc(50% - 12px);
                    color: $green1100;
                    background: $green1000;
                    border-radius: 4px;
                }

                fieldset {
                    border-radius: 4px;
                    border: 1px solid $green1100;
                    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                }

                &.Mui-focused,
                &:hover {
                    fieldset {
                        border-color: $green1100;
                    }
                }
            }
        }
    }

    .leftContentWrapper {
        padding: 20px 50px;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 74px);

        .pageInfo {
            position: fixed;
            left: 32px;
            top: 200px;
            border-radius: 8px;
            border: 1px solid $green1100;
            background: $white;
            box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
            z-index: 2;
            padding: 8px;
            color: $black300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            font-weight: 400;

            .upperInfo {
                span {
                    border-radius: 8px;
                    border: 1px solid $grey300;
                    background: $grey600;
                    display: inline-block;
                    padding: 4px 12px;
                    margin: 8px 0 4px;
                }
            }

            .midInfo {
                margin: 8px 0 0;
                border-top: 1px solid $grey300;
                padding: 8px 0 0;

                span {
                    display: block;
                    cursor: pointer;
                    &.scaleFactor {
                        margin: 8px 0;
                        cursor: default;
                    }

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    svg {
                        font-size: 24px;
                        color: $green600;
                    }
                }
            }
        }

        .fileContent {
            position: relative;
            transition: transform 0.3s ease 0s;
            transform-origin: center;
            left: 50%;

            .pdfViewer {
                width: 100%;
                height: 100%;
                box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);

                canvas {
                    width: 100%;
                    height: 100%;
                }
            }

            &.isImage {
                box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
                img {
                    width: 100%;
                }
            }

            .highlightedBoundingBox {
                position: absolute;
                opacity: 0.5;

                &:hover {
                    .resize-handle {
                        opacity: 1;
                    }
                }

                .resize-handle {
                    position: absolute;
                    opacity: 0;
                }

                .n,
                .s {
                    left: 0;
                    right: 0;
                    height: 6px;
                    width: 100%;
                }

                .n {
                    cursor: n-resize;
                    top: -3px;
                }

                .s {
                    cursor: s-resize;
                    bottom: -3px;
                }

                .e,
                .w {
                    top: 0;
                    bottom: 0;
                    width: 6px;
                    height: 100%;
                }

                .e {
                    cursor: e-resize;
                    right: -3px;
                }

                .w {
                    cursor: w-resize;
                    left: -3px;
                }
            }

            .contentWithTextBox {
                position: absolute;
                border-radius: 8px;
                padding: 16px;
                box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
                background: $white;
                color: $black300;
                min-width: 100px;

                h3 {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    margin: 0 0 4px;
                    word-wrap: break-word;
                }

                .MuiInputBase-root {
                    color: inherit;
                    font-size: inherit;
                    display: block;
                    border-radius: 8px;
                    border: 1px solid $grey800;
                    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                    padding: 8px;
                    background: $white;

                    input {
                        padding: 0;
                        height: auto;
                        line-height: 18px;
                        font-size: 12px;
                    }

                    fieldset {
                        display: none;
                    }
                }
            }
        }
    }

    .rightContent {
        width: 450px;
        height: rem(100);
        background: $white;
        color: $black200;
        box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        &:after {
            display: none;
        }

        & > div {
            flex-grow: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .fieldAndTableContainer {
                flex-grow: 1;
                overflow: auto;
            }
        }

        .buttonContent {
            background: $white;
            box-shadow: 0 -2px 14px -2px rgba(16, 24, 40, 0.06);
            padding: 16px 16px 24px 16px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            // position: absolute;
            // bottom: 0;
            justify-self: flex-end;

            .MuiButton-root {
                margin-right: 10px;
            }

            .verticalIcon {
                border-radius: 8px;
                background: $green500;
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

                padding: 7px 8px 3px;
                cursor: pointer;

                svg {
                    color: $green600;
                    font-size: 20px;
                }
            }
        }

        .headContent {
            display: flex;
            padding: 16px;
            justify-content: space-between;
            align-items: center;

            .leftInfo {
                font-size: 14px;
                font-weight: 400;
                line-height: 30px;
                padding-right: 8px;

                .arrowContent {
                    margin-right: 4px;
                    display: inline-block;

                    span {
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        top: 5px;

                        &:first-child {
                            margin-right: 8px;
                        }
                        &:last-child {
                            margin-left: 8px;
                            svg {
                                transform: rotate(180deg);
                            }
                        }

                        &.disabled {
                            opacity: 0.5;
                            pointer-events: none;
                        }

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .infoIcon {
                    position: relative;
                    top: 3px;
                    margin-right: 8px;
                    cursor: pointer;
                }
            }

            .verticalIcon {
                display: flex;
                padding: 8px 14px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 8px;

                &.settingsEl {
                    background: $green1500;
                }
            }
        }

        .accordionRoot {
            border-radius: 0;
            position: relative;
            box-shadow: none;

            &.docHasTable {
                .MuiCollapse-wrapper {
                    .MuiAccordionDetails-root {
                        // max-height: rem(35);
                    }
                }
            }

            .MuiAccordionSummary-root {
                position: relative;
                border-bottom: 1px solid $grey300;
                margin: 0 16px;

                &.Mui-expanded {
                    min-height: 44px;
                }

                .accordionHead {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 44px;
                    display: block;
                    margin: 0;
                }

                .MuiAccordionSummary-expandIconWrapper {
                    padding: 0;
                    position: absolute;
                    right: 0;

                    svg {
                        height: 24px;
                        width: 24px;
                        fill: $grey700;
                    }
                }
            }

            .MuiCollapse-wrapper {
                display: block;

                .MuiAccordionDetails-root {
                    display: block;
                    padding: 10px 0;
                    // max-height: rem(78);
                    overflow-y: auto;

                    .valueTab {
                        padding: 8px 16px;
                        word-wrap: break-word;
                        display: flex;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        margin-bottom: 4px;
                        cursor: pointer;

                        &.Hidden {
                            display: none;
                        }

                        .key {
                            width: 40%;
                            color: $siteTextColor;
                            position: relative;
                            padding-left: 12px;

                            .circle {
                                left: 0;
                                position: absolute;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                top: 6px;
                            }

                            .rectangle {
                                display: inline-block;
                                padding: 4px 8px;
                                margin-left: 8px;
                                border-radius: 4px;
                                border: 1px solid $green1300;
                                background: $green1000;
                                color: $green600;
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 14px;
                            }
                        }

                        .value {
                            width: 60%;
                            padding-left: 16px;
                            color: $black100;
                            position: relative;
                        }
                    }

                    &.tableWrap {
                        .valueTab {
                            &.activeTable {
                                background: $green1000;
                                pointer-events: none;
                                .key {
                                    .tHead {
                                        font-weight: 600;
                                    }
                                }
                                .value {
                                    svg {
                                        path {
                                            stroke: $green1400;
                                        }
                                    }
                                }
                            }

                            .key {
                                width: 60%;
                                .circle {
                                    top: 9px;
                                }
                            }

                            .value {
                                width: 40%;
                                text-align: right;
                                line-height: 24px;
                                padding-right: 32px;

                                svg {
                                    position: absolute;
                                    width: 24px;
                                    height: 24px;
                                    right: 0;
                                }
                            }
                        }
                    }

                    &.cellWrap {
                        .valueTab {
                            &.activeTable {
                                cursor: default;
                                background: $green1000;

                                .key {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .bottomDummy {
        display: none;
        background: $grey600;
        min-height: 1px;
    }

    .bottomTable {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: rem(40);
        overflow: auto;
        border-top: 1px solid $green1300;
        z-index: 2;
        .close {
            position: absolute;
            top: 10px;
            right: 18px;
            z-index: 4;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 20px 24px -4px #10182814;
            cursor: pointer;
        }

        .MuiTableContainer-root {
            height: 100%;
            background: #fff;
        }

        .MuiInputBase-root {
            color: inherit;
            font-size: inherit;
            display: block;

            input {
                padding: 0;
                height: auto;
                font-size: inherit;
                font-weight: inherit;
            }

            fieldset {
                display: none;
            }
        }

        thead {
            tr {
                th {
                    &:first-child {
                        background: $green1200;
                        padding: 0;
                        width: 40px;
                        max-width: inherit;
                        cursor: default;
                    }

                    padding: 12px 8px;
                    border-bottom: 1px solid $grey700;
                    background: $green500;
                    color: $siteTextColor;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                    max-width: 1px;
                    word-wrap: break-word;
                    cursor: pointer;

                    .MuiInputBase-root {
                        input {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                &:nth-child(2n) {
                    td {
                        background: $grey600;
                    }
                }

                td {
                    &:first-child {
                        background: $grey600;
                        padding: 0;
                        width: 40px;
                        max-width: inherit;
                        text-align: center;
                        cursor: default;
                    }

                    background: $grey500;
                    padding: 8px;
                    color: $black600;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    max-width: 1px;
                    word-wrap: break-word;
                    cursor: pointer;
                }
            }
        }
    }

    .customziedFields {
        position: fixed;
        width: 100%;
        padding: 24px;
        background: $green500;
        left: 0;
        top: 0;
        height: rem(100);
        z-index: 10;

        .header {
            padding: 0;
            color: $siteTextColor;
            font-size: 24px;
            font-weight: 600;
            line-height: 42px;

            .backToReview {
                padding: 0;
                background: transparent;
                cursor: pointer;
                border: 0;
                margin-right: 12px;
                position: relative;
                top: 4px;

                svg {
                    font-size: 24px;
                }
            }

            .buttonWrap {
                float: right;
                button {
                    line-height: 38px;
                    padding: 0 16px;

                    &:first-child {
                        color: $green600 !important;
                        border-color: $green1300;
                        background: $green500;
                        margin: 0 8px 0 0;
                    }
                }
            }
        }
        .customSection {
            padding: 32px;
            background: $white;
            height: calc(100% - 66px);
            margin-top: 24px;
            border-radius: 8px;
            overflow-y: auto;

            .rowInner {
                padding-left: 64px;
            }

            .tableContent {
                margin: 24px 0;
            }

            .customContent {
                line-height: 40px;
                color: $black300;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 12px;

                &.header {
                    font-weight: 600;
                    line-height: 32px;
                    margin-bottom: 16px;
                    .key {
                        padding-left: 32px;
                    }
                }

                &.buttonKey {
                    margin: 24px 0;
                    padding-bottom: 24px;
                    border-bottom: 1px solid $grey300;
                }

                &.Hidden {
                    .key {
                        & > div {
                            opacity: 0.5;
                        }
                    }
                }

                &.inValid {
                    .key {
                        & > div {
                            border-color: $red100;
                        }
                    }
                }

                .key {
                    padding-left: 64px;
                    width: 30%;
                    position: relative;
                    float: left;

                    & > svg {
                        position: absolute;
                        left: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 24px;
                        color: $black100;
                        cursor: pointer;

                        &:first-child {
                            left: -2px;
                        }
                    }

                    & > div {
                        border-radius: 8px;
                        border: 1px solid $grey300;
                        background: $white;
                        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                        padding: 0 14px;
                        min-height: 40px;

                        .MuiInputBase-root {
                            color: inherit;
                            font-size: inherit;
                            line-height: 40px;
                            display: block;

                            input {
                                padding: 0;
                                height: auto;
                            }

                            fieldset {
                                display: none;
                            }
                        }
                    }
                }

                .value {
                    padding: 0 32px 0 24px;
                    width: 30%;
                    float: left;
                    position: relative;

                    svg {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 24px;
                        cursor: pointer;

                        path {
                            stroke: $black100;
                        }
                    }
                }
            }
        }
    }
}

.fieldSettingsDrawer {
    .MuiBackdrop-root {
        background-color: transparent;
    }

    .MuiPaper-root {
        box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
        background: $white;
    }

    .filterContent {
        padding: 24px;
        color: $black;
        height: 100%;
        font-family: 'Inter';

        h2 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin: 0 0 32px;
            position: relative;

            .closeButton {
                position: absolute;
                padding: 0;
                right: 0;
                width: 24px;
                height: 24px;
                color: $black200;
            }
        }

        .rowDrawer {
            margin-bottom: 20px;

            h3 {
                color: $siteTextColor;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin: 0 0 8px 0;
            }

            .textContent {
                border-radius: 8px;
                border: 1px solid $grey300;
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                color: $black300;
                font-size: 16px;
                font-weight: 400;
                line-height: 40px;
                padding: 0 14px;
            }
        }
    }
}

.downloadModal .modalContent {
    width: 656px;
}
