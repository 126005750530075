@import '../../sass/variable';
@import '../../sass/functions';

h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin: 0;

    .MuiButton-root {
        float: right;
        border: none;
    }
}

.modelsDataTable {
    border-radius: 8px;
    // border: 1px solid $grey400;
    // background: $white;
    color: $siteTextColor;
    box-shadow: none !important;
    height: calc(100vh - 120px);
    overflow-y: auto;

    .modelEmptyState {
        height: fill;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .emptyContent {
        margin: 90px 0 120px;
        text-align: center;

        h2 {
            margin: rem(2) 0;
            color: $black200;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }
}

.settingsPopOver {
    &.reviewPopOver {
        .MuiPaper-root {
            & > div {
                &:first-child,
                &:nth-child(2) {
                    color: $black300;

                    svg {
                        path {
                            stroke: $black300;
                        }
                    }
                }
            }
        }
    }

    &.buttonsPopOver {
        .MuiPaper-root {
            margin-top: -66px;
            & > div {
                color: $black300;
            }
        }
    }

    .MuiPaper-root {
        margin-right: 8px;
        margin-top: 4px;
        border-radius: 4px;
        border: 1px solid $grey600;
        background: $white;
        // box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);

        & > div {
            padding: 0 14px;
            font-size: 14px;
            font-weight: 400;
            line-height: 44px;
            cursor: pointer;
            min-width: 211px;
            position: relative;
            color: $green800;
            display: flex;
            align-items: center;
            gap: 12px;

            &:last-child {
                color: $red100;
            }

            &:hover {
                background: $green500;
            }
        }
    }
}

.modalRename .MuiInputBase-root .MuiInputBase-input {
    height: 29px;
    box-sizing: border-box;
    padding: 4px 14px;
    font-size: 14px;
}

.tableCellInCustomTable {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1d2939;
}

.statusWrap {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    color: $green600;
    border: 1px solid $green1100;
    background: $green1000;
    display: inline-block;
    font-weight: 500;

    &.Pending.Review {
        color: $green600;
        border-color: $green1100;
        background: $green1000;
    }

    &.Processed {
        color: $green400;
        border-color: $green200;
        background: $green300;
    }

    &.Upload.Pending {
        color: $red200;
        border-color: $orange100;
        background: $yellow200;
    }
}
.nameWrap {
    font-weight: 500;
    color: $black200;
    width: 35%;
    cursor: pointer;
}

.verticalIcon {
    color: $grey700;
    position: relative;
    cursor: pointer;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
    }
}

.MuiDataGrid-virtualScrollerContent {
    height: unset !important;
}
