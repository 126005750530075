@import '../../sass/variable';

.uploadDocModalRoot {
    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 24px;
        width: 512px;
        border-radius: 12px;
        background: $white;
        box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
        color: $black200;
        outline: none;

        h2 {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin: 0 0 16px;
            position: relative;

            .closeButton {
                position: absolute;
                padding: 0;
                right: 0;
                width: 24px;
                height: 24px;
                color: $black200;
            }
        }

        .errorInfo {
            li {
                font-size: 14px;
                font-weight: 400;
                color: $red100;
            }
        }

        .dragDropFile {
            text-align: center;
            padding: 24px 0;
            border-radius: 8px;
            border: 1px dashed $green1100;
            cursor: pointer;

            &.drag-active {
                background: $green500;
            }

            &.drag-reject {
                background: rgba($orange100, 0.1);
                border-color: $red100;
            }

            img {
                width: 68px;
            }

            h3 {
                color: $siteTextColor;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                margin: 10px 0 4px;
            }

            p {
                &.fileUploaded {
                    color: $siteTextColor;
                    font-size: 14px;
                }

                color: $black100;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                margin: 0;
            }
        }

        .selectWrap {
            margin-top: 16px;

            label {
                font-weight: 600;
            }
        }

        .buttonWrap {
            text-align: center;
            margin-top: 32px;

            button {
                position: relative;
                padding: 10px 18px;
                width: calc(50% - 6px);
                margin-left: 12px;
                font-size: 16px;

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                &:first-child {
                    color: $black300 !important;
                    border-color: $grey300;
                    background: $white;
                    margin: 0;
                }
            }
        }
    }
}
