@import '../../sass/variable';
@import '../../sass/functions';

.wrapper {
    min-height: 100%;
    position: relative;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .loader {
        background: transparent !important;
    }
    .loginHead {
        text-align: center;
    }

    .loginWrapper {
        width: 100%;
        max-width: 780px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        position: relative;

        h1 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: #1d2939;
        }
        &.signUpSuccess {
            h1 {
                text-align: center;
            }
        }
        .forgotHeader {
            font-size: 30px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            color: #344054;
        }
        .forgotSubHeader {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #344054;
        }

        .ssoContainer {
            display: flex;
            gap: 24px;
            .ssoButton {
                width: calc(50% - 12px);
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                background: #fff;
                border: 1px solid #d0d5dd;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: #344054;
                cursor: pointer;
            }
        }
        .orDivider {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #1d2939;
            display: flex;
            align-items: center;
            gap: 24px;
            hr {
                width: calc(50% - 34px);
                height: 1px;
                border: 0;
                background-color: #d0d5dd;
            }
        }

        h2 {
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            margin: 16px 0;
            color: $red100;
            text-align: center;

            &.signUpSuccess {
                color: $green200;
            }
        }

        .halfWidthWrap {
            & > div {
                float: left;
                width: 50%;

                &:first-child {
                    padding-right: 2%;
                }
                &:last-child {
                    padding-left: 2%;
                }
            }
        }

        .inputWrap {
            margin-bottom: 24px;

            &.errorWrap {
                .MuiInputBase-root {
                    border: 1px solid $red100;
                }
            }

            .warning {
                margin-top: 8px;
                color: $red100;
                font-weight: 500;
            }

            label {
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 12px;
            }

            .MuiInputBase-root {
                border-radius: 8px;
                border: 1px solid $grey300;
                background: $white;
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

                input {
                    height: inherit;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 10px 14px;
                }

                fieldset {
                    display: none;
                }
            }
        }

        .MuiButton-root {
            margin-top: 8px;
            padding: 8px 14px;
        }

        .infoWrap {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            margin-top: 8px;

            span {
                color: $green600;
                cursor: pointer;
            }
        }
    }
}

.success {
    color: $siteTextColor;
    margin: 30px 0;
    text-align: center;

    .checkOuter {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        position: relative;
        background: $green700;
        display: inline-block;

        & > div {
            width: 136px;
            height: 136px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $green800;
            display: inline-block;

            & > svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 110px;
                color: $white;
            }
        }
    }

    h2 {
        font-size: 20px;
    }

    .infoWrap {
        font-size: 16px;
    }
}

.signUpStepTwo {
    display: flex;
    height: 100%;
    .stepsWrapper {
        min-width: 40%;
        max-width: 40%;
    }
    .loginWrapper {
        width: 80%;
        max-width: 584px;
    }
}
.signupStepperWrapper {
    height: 100%;
    background: linear-gradient(180deg, #b4e8e470 0%, #eefbfa7a 100%);
    .signupStepperContainer {
        flex-direction: column;
        align-items: flex-start;
        display: flex;
        background-image: url(../../assets/signupEarth.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
        height: 100%;
        gap: 15px;
        .logo {
            margin: 32px 66px;
            height: 60px;
        }
        .stepperContainer {
            background: linear-gradient(180deg, #a6f4c55b 0%, #e3fff100 100%);
            width: 100%;
            padding: 40px 66px;
            .stepperItem {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-bottom: 60px;
                .stepperCount {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    &.prevStep {
                        background: #12b76a;
                    }
                    &.activeStep {
                        background: #6ce9a6;
                        border: 8px solid #a6f4c5;
                    }
                    &.nextStep {
                        background: #eaecf0;
                        border: 1px solid #d0d5dd;
                    }
                }
                .stepperText {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                    &.prevStep {
                        color: #027a48;
                    }
                    &.activeStep {
                        color: #344054;
                        font-weight: 600;
                    }
                    &.nextStep {
                        color: #667085;
                    }
                }
            }
        }
    }
}

.wrapper .loginWrapper .autoComplete .MuiInputBase-root input {
    padding: 0;
    height: 30px;
}

.verifyContainer {
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: center;
    .header {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        color: #101828;
        margin-bottom: 16px;
    }
    .subHeader {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #344054;
    }

    .resend {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-top: 8px;
        span {
            color: #038e83;
        }
    }
}

.dialogContainer {
    .text {
        h3 {
            margin-top: 12px;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color: #101828;
        }
        p {
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #344054;
        }
    }
}

.warning {
    margin-top: 8px;
    color: $red100;
    font-weight: 500;
}

.bottomMenu {
    margin-top: -20px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    .forgotPassword {
        color: $green600;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.otpInputGroup {
    background-color: red;
}
.otpInput {
    width: 55px !important;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #d0d5dd;
    //styleName: Display md/Regular;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: center;
}

.otpInput.errorWrap {
    border-color: red !important;
}
